import React, { useState } from "react";
import { Icon } from "@iconify/react";
import axios from "axios";
import Modal from "../../../../../../../Generic/Modal";
import UserPermissions from "./UserActions/UserPermissions/UserPermissions";
import AddEditUsers from "./UserActions/AddEditUsers";
import { useSiteContext } from "../../../../../../../Context";
import * as Constants from "../../../../../../../Constants";

function UserItem(props) {
  const [showUserPermissionsModal, SetShowUserPermissionsModal] =
    useState(false);
  const [showAddEditUserModal, SetShowAddEditUserModal] = useState(false);
  const { loggedInUser, AddAlert, fieldCustomizations } = useSiteContext();

  function PermissionsModal() {
    SetShowUserPermissionsModal(!showUserPermissionsModal);
  }

  function AddEditUserModal() {
    SetShowAddEditUserModal(!showAddEditUserModal);
  }

  function DeleteUser() {
    if (
      window.confirm(
        "Are you sure you want to delete this " +
          fieldCustomizations.general.user.name.toLowerCase() +
          "?"
      )
    ) {
      let formData = new FormData();
      formData.append("id", props.user.id);
      axios({
        method: "delete",
        url:
          Constants.API_URL +
          `library/User/${props.user.id}` +
          Constants.API_KEY,
        headers: {
          "Content-Type": "multipart/form-data",
          UserId: loggedInUser.id,
          RemoveType: "User",
        },
        data: formData,
      })
        .then((res) => {
          if (res.data !== "Failed") {
            AddAlert(
              "notification",
              fieldCustomizations.general.user.name + " deleted successfully"
            );
            props.RefreshUsers();
          } else {
            AddAlert(
              "error",
              fieldCustomizations.general.user.name + " could not be deleted"
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <>
      <div className="adminModalBodyItemContainer userItem">
        <div className="adminModalTableValue">
          {props.user.firstName} {props.user.lastName}
        </div>
        <div className="adminModalTableValue hiddenOnSmall">
          {props.user.email != null &&
          props.user.email
            .toLowerCase()
            .includes(loggedInUser.customer.id.toLowerCase())
            ? "Default Admin"
            : props.user.email}
        </div>
        <div className="adminModalTableValue">
          <Icon
            className="editIcon"
            icon="bxs:edit"
            onClick={AddEditUserModal}
          />
        </div>
        <div className="adminModalTableValue">
          <Icon
            className="permissionsIcon"
            icon="icon-park-outline:permissions"
            onClick={PermissionsModal}
          />
        </div>
        <div className="adminModalTableValue">
          <Icon
            className="permissionsIcon"
            icon="akar-icons:trash-can"
            onClick={DeleteUser}
          />
        </div>
      </div>
      {showUserPermissionsModal ? (
        <Modal
          CloseModal={PermissionsModal}
          modificationClass="noPaddingAlternate"
        >
          <UserPermissions
            CloseModal={PermissionsModal}
            user={props.user}
            RefreshUsers={props.RefreshUsers}
          />
        </Modal>
      ) : null}
      {showAddEditUserModal ? (
        <Modal
          CloseModal={AddEditUserModal}
          modificationClass="noPaddingAlternate"
        >
          <AddEditUsers
            editMode={true}
            CloseModal={AddEditUserModal}
            user={props.user}
            RefreshUsers={props.RefreshUsers}
          />
        </Modal>
      ) : null}
    </>
  );
}

export default UserItem;
